import { formatDate, parseDate } from '../dates';

import md5 from 'md5';
import { validId as nonEmptyValue } from '../matchers';

const csvDateFormat = 'MM/dd/yyyy';

const identity = val => val;

export const mapArrayToColumn = (itemMapper = identity) => items =>
  items ? items.reduce((acc, val) => acc.concat(itemMapper(val)), []).join('|') : '';

export const constantMap = val => () => val;

export const mapItemFromValue = mapper => (_, item) => mapper(item);

export const alternateFieldMap = field => mapItemFromValue(item => item[field]);

export const createMapWithMappings = (specialMappings = {}, droppedFields = []) => item =>
  droppedFields.reduce(
    (acc, val) => {
      delete acc[val];
      return acc;
    },
    Object.entries(specialMappings).reduce(
      (acc, [fieldName, mapper]) => ({
        ...acc,
        [fieldName]: mapper(acc[fieldName], acc),
      }),
      item
    )
  );

export const dateMap = val => {
  try {
    nonEmptyValue(val) ? formatDate(parseDate(val), csvDateFormat) : '';
  } catch (err) {
    return '';
  }
};

export const basicMap = item => item;

export const truncatedMd5Map = val => md5(val).slice(0, 24);
