import { Container, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { alwaysMatches, createAndMatch as where } from '../utilities/criteria';
import { changeVerifiedStatus, isGrowFlowEmployee } from '../utilities/users';
import { csvExports, reportFileNameBuilder } from '../utilities/csv';
import { getCustomer, getCustomers } from '../utilities/customers';
import { getUser, impersonateUser } from '../utilities/auth';

import CsvDownload from 'react-json-to-csv';
import MaterialTable from 'material-table';
import { WizardLayout } from '../components';
import { navigate } from 'gatsby';

const NUMBER_OF_REPORTS_PER_REPORT_ROW = 2;

const reportRows = Array(csvExports.length)
  .fill(null)
  .reduce((acc, val, index) => {
    const rowIndex = Math.floor(index / NUMBER_OF_REPORTS_PER_REPORT_ROW);
    const columnIndex = index % NUMBER_OF_REPORTS_PER_REPORT_ROW;

    if (!acc[rowIndex]) acc[rowIndex] = [];

    acc[rowIndex][columnIndex] = csvExports[index] || emptyReport;

    return acc;
  }, []);

const mapToTableRow = customer => {
  const steps = Object.values(customer.stepStatus || {}) || [];
  const completed = steps.filter(step => step.completed).length;
  const started = steps.filter(step => step.started).length;
  const lastStepUpdatedAt = Math.max(...(steps.map(step => step.updatedAt) || []));
  const lastEditDate = new Date(lastStepUpdatedAt > 0 ? lastStepUpdatedAt : '2019-10-01');
  const { startAt } = customer;
  const startAtDate = new Date(startAt || '2019-10-01');
  const products = [];
  if (customer.wantsToUseGrowFlowProduct) {
    products.push('Wholesale');
  }
  if (customer.wantsToUseRetailProduct) {
    products.push('Retail');
  }
  if (products.length === 0) {
    products.push('None');
  }
  return {
    customer,
    name: customer.companyName,
    product: `${products.join(', ')}`,
    verified: customer.verified,
    state: customer.state,
    startAtDate,
    lastEditDate,
    startAt: `${startAtDate.toLocaleString()}`,
    lastEditAt: `${lastEditDate.toLocaleString()}`,
    progress: `${started} / ${completed}`,
  };
};

function Admin() {
  const [customers, setCustomers] = useState([]);
  const [csvCustomer, setCsvCustomer] = useState(null);
  const [contactInfoCustomer, setContactInfoCustomer] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [hideVerified] = useState(false);
  const impersonate = impersonateUser();

  const applicableCustomers = customers.filter(
    where({
      verified: hideVerified ? false : alwaysMatches,
    })
  );

  const createImpersonationHandler = email => e => {
    e.preventDefault();
    impersonate(email);
  };

  useEffect(() => {
    getCustomers().then(customers => {
      setCustomers(customers || []);
    });
  }, []);

  const user = getUser();

  // This is a slightly awkward place for this,
  // it's used by all navigation in the "main" part of the app
  // TODO -- Move to a better wrapper / decorator
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
    if (!isGrowFlowEmployee(user.name)) {
      navigate('/');
    }
  }, [!!user]);

  if (!user) {
    return null;
  }

  const clearCustomerSelection = () => {
    setCsvCustomer(null);
    setContactInfoCustomer(null);
    setCustomerEmail(null);
  };

  const fetchCustomer = async (customerEmail, cb) => {
    setCustomerEmail(customerEmail);
    const data = await getCustomer(customerEmail);
    cb(customerEmail, data);
  };

  const reportButton = ({ title, suffix, builder }) => (
    <CsvDownload
      className="ui button fluid employee-csv-button"
      data={csvCustomer ? builder(csvCustomer) : []}
      filename={csvCustomer ? reportFileNameBuilder(csvCustomer, suffix) : ''}
    >
      {title}
    </CsvDownload>
  );

  const toggleVerified = async (event, { checked }, { customer: { email } }) => {
    if (await changeVerifiedStatus({ email, verified: checked })) {
      getCustomers().then(customers => {
        setCustomers(customers);
      });
    }
  };

  const renderVerifiedSlider = rowData => (
    <Form.Checkbox
      fitted
      toggle
      checked={rowData.customer.verified}
      onChange={(event, data) => {
        (async () => {
          await toggleVerified(event, data, rowData);
        })();
      }}
    />
  );

  const [columns] = useState([
    {
      title: 'Company',
      field: 'name',
      cellStyle: { maxWidth: '8px' },
      render: ({ name, customer: { email } }) => (
        <div>
          <a href="#" onClick={createImpersonationHandler(email)}>
            <span>{name}</span>
          </a>
        </div>
      ),
    },
    {
      title: 'Product(s)',
      field: 'product',
      searchable: false,
    },
    { title: 'State', field: 'state' },
    {
      title: 'Start',
      field: 'startAt',
      searchable: false,
      filtering: false,
      customSort: (a, b) => a.startAtDate - b.startAtDate,
    },
    {
      title: 'Last Edit',
      field: 'lastEditAt',
      searchable: false,
      filtering: false,
      customSort: (a, b) => a.lastEditDate - b.lastEditDate,
    },
    {
      title: 'Steps Started / Completed',
      field: 'progress',
      filtering: false,
      searchable: false,
    },
    {
      title: 'Verified',
      field: 'verified',
      cellStyle: { maxWidth: '20px' },
      lookup: { true: 'Verified', false: 'Unverified' },
      filtering: true,
      render: renderVerifiedSlider,
    },
  ]);

  const customerTable = (
    <MaterialTable
      options={{
        pageSize: 25,
        pageSizeOptions: [25, 50, 100, 250],
        search: true,
        filtering: true,
        actionsColumnIndex: -1,
      }}
      columns={columns}
      actions={[
        {
          icon: 'receipt',
          tooltip: 'CSVs',
          iconProps: { color: 'primary' },
          onClick: (event, rowData) =>
            fetchCustomer(rowData.customer.email, (email, data) => {
              setCsvCustomer(data);
            }),
        },
        {
          icon: 'account_box',
          tooltip: 'Contact',
          iconProps: { color: 'primary' },
          onClick: (event, rowData) =>
            fetchCustomer(rowData.customer.email, (email, data) => {
              setContactInfoCustomer(data);
            }),
        },
        {
          icon: hideCompleted ? 'visibility_off' : 'visibility',
          tooltip: hideCompleted ? 'Show Completed' : 'Hide Completed',
          isFreeAction: true,
          onClick: () => setHideCompleted(!hideCompleted),
        },
      ]}
      data={applicableCustomers.map(mapToTableRow)}
      title="Account Admin"
    />
  );
  return (
    <WizardLayout className="admin">
      <Grid stretched stackable>
        <Grid.Column width={16}>
          <Container text className="padding-top main-container">
            <main>
              {customers.length === 0 && (
                <Loader active size="massive">
                  Loading
                </Loader>
              )}
              {customers.length > 0 && customerTable}
              <Modal open={customerEmail} onClose={clearCustomerSelection}>
                {csvCustomer && (
                  <>
                    <Modal.Header>Download CSVs for {customerEmail} </Modal.Header>
                    <Modal.Content>
                      <Grid columns={NUMBER_OF_REPORTS_PER_REPORT_ROW}>
                        {reportRows.map(reports => (
                          <Grid.Row equals>
                            {reports.map(report => (
                              <Grid.Column equals>{reportButton(report)}</Grid.Column>
                            ))}
                          </Grid.Row>
                        ))}
                      </Grid>
                    </Modal.Content>
                  </>
                )}
                {contactInfoCustomer && (
                  <pre>{JSON.stringify(contactInfoCustomer, null, ' ')}</pre>
                )}
                {!csvCustomer && !contactInfoCustomer && <div>Loading...</div>}
              </Modal>
            </main>
          </Container>
        </Grid.Column>
      </Grid>
    </WizardLayout>
  );
}

export default Admin;
