import { dateMap } from './utils';
import { growingStages } from '../plants';

export default plant => ({
  CLONE_ID: '',
  UNIQUE_ID: '',
  STRAIN: plant.strain.name,
  DOB: dateMap(plant.dateOfBirth),
  ROOM: plant.room.name,
  TABLE: '',
  STAGE: growingStages[plant.stage].text,
  PLANT_COUNT: plant.plantCount,
  HARVEST_DATE: dateMap(plant.harvestDate),
  VEG_DATE: '',
  FLOWER_DATE: '',
  DRY_WEIGHT: plant.dryWeight,
});
