import inventoryCSVMap, { createInventoryGroupMappers } from './csvMaps/inventory';
import { basicMap } from './csvMaps/utils';

import employeeCSVMap from './csvMaps/employee';
import licenseInformationMap from './csvMaps/licenseInformation';
import plantCSVMap from './csvMaps/plant';
import plantRoomMap from './csvMaps/plantRoom';
import vehicleMap from './csvMaps/vehicle';

const values = item => Object.values(item || {});

const mappedValues = (item, mapper) => values(item).map(mapper);

const csvQueries = ({
  introInformation = {},
  strainInformation = {},
  plantsInformation = {},
  plantRooms = {},
  inventoryRooms = {},
  employeeInformation = {},
  licenseDetails = {},
  printersAndLabelsInformation = {},
  licenseInformation = {},
  vehicleInformation = {},
  seedGroupsInformation,
  cloneGroupsInformation,
  bulkFlowerGroupsInformation,
  shakeTrimGroupsInformation,
}) => {
  const allRooms = values(plantRooms)
    .concat(values(inventoryRooms))
    .reduce((acc, val) => ({ [val.id]: val, ...acc }), {});

  const {
    seedGroupAssociationMap,
    cloneGroupAssociationMap,
    bulkFlowerGroupAssociationMap,
    shakeTrimGroupAssociationMap,
  } = createInventoryGroupMappers({ rooms: allRooms, strains: strainInformation });

  const allInventoryGroups = [].concat(
    mappedValues(seedGroupsInformation, seedGroupAssociationMap),
    mappedValues(cloneGroupsInformation, cloneGroupAssociationMap),
    mappedValues(bulkFlowerGroupsInformation, bulkFlowerGroupAssociationMap),
    mappedValues(shakeTrimGroupsInformation, shakeTrimGroupAssociationMap)
  );

  return {
    introInformation: [introInformation],
    employees: values(employeeInformation),
    inventoryRooms: values(inventoryRooms),
    plantRooms: values(plantRooms),
    licenseDetails: values(licenseDetails),
    printersAndLabels: values(printersAndLabelsInformation),
    strains: values(strainInformation),
    vehicles: values(vehicleInformation),
    licenseInformation: [licenseInformation],

    plantsWithAssociations: values(plantsInformation).map(plant => ({
      room: allRooms[plant.roomId],
      strain: strainInformation[plant.strainId],
      ...plant,
    })),

    allInventoryGroups,
  };
};

const createCSVGenerator = (queryKey, mapper = basicMap) => customer =>
  csvQueries(customer)[queryKey].map(mapper);

export const csvExports = [
  {
    title: 'Employees',
    builder: createCSVGenerator('employees', employeeCSVMap),
    suffix: 'Employees',
  },
  {
    title: 'Plants',
    builder: createCSVGenerator('plantsWithAssociations', plantCSVMap),
    suffix: 'Plants',
  },
  {
    title: 'Inventory',
    builder: createCSVGenerator('allInventoryGroups', inventoryCSVMap),
    suffix: 'Inventory',
  },
  {
    title: 'Intro Information',
    builder: createCSVGenerator('introInformation'),
    suffix: 'IntroInformation',
  },
  {
    title: 'Inventory Rooms',
    builder: createCSVGenerator('inventoryRooms'),
    suffix: 'InventoryRooms',
  },
  {
    title: 'License Details',
    builder: createCSVGenerator('licenseDetails'),
    suffix: 'LicenseDetails',
  },
  {
    title: 'License Information',
    builder: createCSVGenerator('licenseInformation', licenseInformationMap),
    suffix: 'LicenseInformation',
  },
  {
    title: 'Plant Rooms',
    builder: createCSVGenerator('plantRooms', plantRoomMap),
    suffix: 'PlantRooms',
  },
  {
    title: 'Printers & Labels',
    builder: createCSVGenerator('printersAndLabels'),
    suffix: 'PrintersAndLabels',
  },
  {
    title: 'Strains',
    builder: createCSVGenerator('strains'),
    suffix: 'Strains',
  },
  {
    title: 'Vehicles',
    builder: createCSVGenerator('vehicles', vehicleMap),
    suffix: 'Vehicles',
  },
];

export const customerPrefix = intro => `${intro.companyName}-${intro.email}`;

export const reportFileNameBuilder = (customer, suffix) =>
  `${customer &&
    customer.introInformation &&
    customerPrefix(customer.introInformation)}-${suffix}.csv`;
