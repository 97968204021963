import {
  alternateFieldMap,
  constantMap,
  createMapWithMappings,
  dateMap,
  mapItemFromValue,
} from './utils';

const UNIT_TYPES = {
  grams: 'Grams',
  units: 'Units',
};

const TYPES = {
  bulkFlowerGroup: 'Flower',
  cloneGroup: 'Clone',
  seedGroup: 'Seeds (Each)',
  shakeTrimGroup: 'Shake/Trim',
};

const createGroupAssociationMap = ({
  inventoryUOM,
  packageSizeField,
  packageSizeUOM,
  quantityField,
  rooms,
  strains,
  type,
}) =>
  createMapWithMappings({
    inventoryUOM: constantMap(inventoryUOM),

    packageSize: packageSizeField ? alternateFieldMap(packageSizeField) : constantMap(''),

    packageSizeUOM: constantMap(packageSizeUOM),

    quantity: quantityField
      ? alternateFieldMap(quantityField)
      : constantMap(quantityField),

    room: mapItemFromValue(val => rooms[val.roomId].name),

    strain: mapItemFromValue(val => strains[val.strainId].name),

    type: constantMap(type),
  });

const unitFields = {
  inventoryUOM: UNIT_TYPES.units,

  packageSizeUOM: UNIT_TYPES.units,
};

const weightFields = {
  inventoryUOM: UNIT_TYPES.grams,

  packageSizeField: 'weightInGrams',

  packageSizeUOM: UNIT_TYPES.grams,
};

export const createInventoryGroupMappers = ({ rooms, strains }) => ({
  seedGroupAssociationMap: createGroupAssociationMap({
    ...unitFields,
    quantityField: 'seedCount',
    rooms,
    strains,
    type: TYPES.seedGroup,
  }),

  cloneGroupAssociationMap: createGroupAssociationMap({
    ...unitFields,
    quantityField: 'cloneCount',
    rooms,
    strains,
    type: TYPES.cloneGroup,
  }),

  bulkFlowerGroupAssociationMap: createGroupAssociationMap({
    ...weightFields,
    quantityField: 'weightInGrams',
    rooms,
    strains,
    type: TYPES.bulkFlowerGroup,
  }),

  shakeTrimGroupAssociationMap: createGroupAssociationMap({
    ...weightFields,
    quantityField: 'weightInGrams',
    rooms,
    strains,
    type: TYPES.shakeTrimGroup,
  }),
});

export default item => ({
  PARENT_ID: '',
  UNIQUE_ID: '',
  STRAIN: item.strain,
  DOB: dateMap(item.dateOfBirth),
  ROOM: item.room,
  TYPE: item.type,
  QUANTITY: item.quantity,
  INVENTORY_UOM: item.inventoryUOM,
  PACKAGE_SIZE: '',
  PACKAGE_SIZE_UOM: '',
  QA_ID: '',
  PRODUCER_LICENSE: '',
  PRODUCER_NAME: '',
  UNIT_PRICE: '',
  PRODUCT_NAME: '',
  BRAND_NAME: '',
});
