import { dateMap, truncatedMd5Map } from './utils';

export default employee => ({
  UNIQUE_ID: truncatedMd5Map(employee.email),
  EMAIL: employee.email,
  FIRST_NAME: employee.firstName,
  LAST_NAME: employee.lastName,
  DATE_OF_BIRTH: dateMap(employee.dateOfBirth),
  DATE_OF_HIRE: dateMap(employee.dateOfHire),
  PHONE: employee.phoneNumber,
  DRIVERS_LICENSE: employee.driverLicenseNumber,
});
